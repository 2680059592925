/* 
    Custom modernizr tests I feel like adding 
    I'm moving the items used from jsCSSBrowserSelector.js
    into here to fix a jquery3 deprecation issue with that script.
    Yeah yeah yeah, feature detection, blah blah blah, we don't really
    use these for anything that's a feature we can detect, just quick
    fixes and tweaks. There can be false positives because of MS
    having the word iPhone in their phone UA, but those phones
    suck anyway
*/

var detects = {
    'ipad': !!navigator.userAgent.match(/iPad/i),
    'iphone': !!navigator.userAgent.match(/iPhone/i),
    'ipod': !!navigator.userAgent.match(/iPod/i),
    'ios': !!navigator.userAgent.match(/(iPad|iPhone|iPod)/i),
    'android': !!navigator.userAgent.match(/Android/i),
    'safari10': !!navigator.userAgent.match(/Version\/10.0 Mobile.*Safari/)
}
Modernizr.addTest(detects);
