/* global stickyWidget farkDebug fark:true*/
/* exported isResize getJSONFromString:true */

// from js/jqueryFunctions.js
// using a slight bastardization of the coda bubble tutorial from http://jqueryfordesigners.com
// the SHARE box for the headline

// $( function() {
//     $( "#headlineCommentsInfoLineThingy" ).each( function() {

//         // options
//         var distance = 10,
//             time = 250,
//             hideDelay = 50,
//             hideDelayTimer = null,
//             beingShown = false,
//             shown = false,
//             triggerOpen = $( "#openHeadlineCommentsInfoLineThingy", this ),
//             triggerClose = $( "#closeHeadlineCommentsInfoLineThingy", this ),
//             popup = $( "#headlineCommentsMoreLinks", this ).css( "opacity", 0 );

//         // set the mouseover and mouseout on both element
//         $( [ triggerOpen.get( 0 ) ] ).on( "click", function() {

//             // stops the hide event if we move from the trigger to the popup element
//             //if (hideDelayTimer) clearTimeout(hideDelayTimer);

//             // don't trigger the animation again if we're being shown, or already visible
//             if ( beingShown || shown ) {
//                 return;
//             } else {
//                 beingShown = true;

//                 // reset position of popup box
//                 // var popupHeight = $( "#headlineCommentsInfoLineThingy" ).height();
//                 var popupWidth = $( "#headlineCommentsInfoLineThingy" ).width();

//                 var leftVal = ( popupWidth / 2 ) - 5 + "px";
//                 //topVal=(popupHeight/2)-100+"px";

//                 popup.css( {
//                     top: -82,
//                     left: leftVal,
//                     display: "block" // brings the popup back in to view
//                 } )

//                 // (we're using chaining on the popup) now animate it's opacity and position
//                     .animate( {
//                         top: "-=" + distance + "px",
//                         opacity: 1
//                     }, time, "swing", function() {
//                     // once the animation is complete, set the tracker variables
//                         beingShown = false;
//                         shown = true;
//                     } );
//             }
//         } );

//         $( [ triggerClose.get( 0 ) ] ).on( "click", function() {
//             // reset the timer if we get fired again - avoids double animations
//             if ( hideDelayTimer ) {clearTimeout( hideDelayTimer );}

//             // store the timer so that it can be cleared in the mouseover if required
//             hideDelayTimer = setTimeout( function() {
//                 hideDelayTimer = null;
//                 popup.animate( {
//                     top: "-=" + distance + "px",
//                     opacity: 0
//                 }, time, "swing", function() {
//                     // once the animate is complete, set the tracker variables
//                     shown = false;
//                     // hide the popup entirely after the effect (opacity alone doesn't do the job)
//                     popup.css( "display", "none" );
//                 } );
//             }, hideDelay );
//         } );
//     } );
// } );


// The SHARE box that pops up on each comment

$(function () {
    $(".commentInfoLineThingy").each(function () {
        // options
        var distance = 10;
        var time = 250;
        var hideDelay = 50;

        var hideDelayTimer = null;

        // tracker
        var beingShown = false;
        var shown = false;

        var triggerOpen = $("a[rel=more]", this);
        var triggerClose = $("a[rel=closeShareComment]", this);
        var popup = $(".commentMoreLinks", this).css("opacity", 0);

        // set the mouseover and mouseout on both element
        $([triggerOpen.get(0)]).on("click", function () {
            // stops the hide event if we move from the trigger to the popup element
            //if (hideDelayTimer) clearTimeout(hideDelayTimer);

            // don't trigger the animation again if we're being shown, or already visible
            if (beingShown || shown) {
                return;
            } else {
                beingShown = true;

                // reset position of popup box
                // var popupHeight = $( ".commentInfoLineThingy" ).height();
                var popupWidth = $(".commentInfoLineThingy").width();

                var leftVal = (popupWidth / 2) - 33 + "px";
                // var topVal = ( popupHeight / 2 ) - 100 + "px";


                popup.css({
                    top: -130,
                    left: leftVal,
                    display: "block" // brings the popup back in to view
                })

                    // (we're using chaining on the popup) now animate it's opacity and position
                    .animate({
                        top: "-=" + distance + "px",
                        opacity: 1
                    }, time, "swing", function () {
                        // once the animation is complete, set the tracker variables
                        beingShown = false;
                        shown = true;
                    });
            }
        });

        $([triggerClose.get(0)]).on("click", function () {
            // reset the timer if we get fired again - avoids double animations
            if (hideDelayTimer) { clearTimeout(hideDelayTimer); }

            // store the timer so that it can be cleared in the mouseover if required
            hideDelayTimer = setTimeout(function () {
                hideDelayTimer = null;
                popup.animate({
                    top: "-=" + distance + "px",
                    opacity: 0
                }, time, "swing", function () {
                    // once the animate is complete, set the tracker variables
                    shown = false;
                    // hide the popup entirely after the effect (opacity alone doesn't do the job)
                    popup.css("display", "none");
                });
            }, hideDelay);
        });
    });
});


// The FRIEND box that pops up on each comment

$(function () {
    $(".commentHeader").each(function () {
        // options
        var distance = 10;
        var time = 250;
        var hideDelay = 50;

        var hideDelayTimer = null;

        // tracker
        var beingShown = false;
        var shown = false;

        var triggerOpen = $("a[rel=friend]", this);
        var triggerClose = $("a[rel=closeAddFriend]", this);
        var popup = $(".commentAddFriend", this).css("opacity", 0);

        // set the mouseover and mouseout on both element
        $([triggerOpen.get(0)]).on("click", function () {
            // stops the hide event if we move from the trigger to the popup element
            //if (hideDelayTimer) clearTimeout(hideDelayTimer);

            // don't trigger the animation again if we're being shown, or already visible
            if (beingShown || shown) {
                return;
            } else {
                beingShown = true;

                // reset position of popup box
                // var popupHeight = $( ".commentHeader" ).height();
                // var popupWidth = $( ".commentHeader" ).width();

                //leftVal=(popupWidth)+23+"px";
                //topVal=(popupHeight/2)-100+"px";


                popup.css({
                    top: 10,
                    left: 150,
                    display: "block" // brings the popup back in to view
                })

                    // (we're using chaining on the popup) now animate it's opacity and position
                    .animate({
                        top: "+=" + distance + "px",
                        opacity: 1
                    }, time, "swing", function () {
                        // once the animation is complete, set the tracker variables
                        beingShown = false;
                        shown = true;
                    });
            }
        });

        $([triggerClose.get(0)]).on("click", function () {
            // reset the timer if we get fired again - avoids double animations
            if (hideDelayTimer) { clearTimeout(hideDelayTimer); }

            // store the timer so that it can be cleared in the mouseover if required
            hideDelayTimer = setTimeout(function () {
                hideDelayTimer = null;
                popup.animate({
                    top: "+=" + distance + "px",
                    opacity: 0
                }, time, "swing", function () {
                    // once the animate is complete, set the tracker variables
                    shown = false;
                    // hide the popup entirely after the effect (opacity alone doesn't do the job)
                    popup.css("display", "none");
                });
            }, hideDelay);
        });
    });
});


// The IGNORE box that pops up on each comment

$(function () {
    $(".commentHeader").each(function () {
        // options
        var distance = 10;
        var time = 250;
        var hideDelay = 50;

        var hideDelayTimer = null;

        // tracker
        var beingShown = false;
        var shown = false;

        var triggerOpen = $("a[rel=ignore]", this);
        var triggerClose = $("a[rel=closeIgnore]", this);
        var popup = $(".commentIgnoreConfirm", this).css("opacity", 0);

        // set the mouseover and mouseout on both element
        $([triggerOpen.get(0)]).on("click", function () {
            // stops the hide event if we move from the trigger to the popup element
            //if (hideDelayTimer) clearTimeout(hideDelayTimer);

            // don't trigger the animation again if we're being shown, or already visible
            if (beingShown || shown) {
                return;
            } else {
                beingShown = true;

                // reset position of popup box
                // var popupHeight = $( ".commentHeader" ).height();
                var popupWidth = $(".commentHeader").width();

                var leftVal = (popupWidth) - 223 + "px";
                //topVal=(popupHeight/2)-100+"px";


                popup.css({
                    top: 10,
                    left: leftVal,
                    display: "block" // brings the popup back in to view
                })

                    // (we're using chaining on the popup) now animate it's opacity and position
                    .animate({
                        top: "+=" + distance + "px",
                        opacity: 1
                    }, time, "swing", function () {
                        // once the animation is complete, set the tracker variables
                        beingShown = false;
                        shown = true;
                    });
            }
        });

        $([triggerClose.get(0)]).on("click", function () {
            // reset the timer if we get fired again - avoids double animations
            if (hideDelayTimer) { clearTimeout(hideDelayTimer); }

            // store the timer so that it can be cleared in the mouseover if required
            hideDelayTimer = setTimeout(function () {
                hideDelayTimer = null;
                popup.animate({
                    top: "+=" + distance + "px",
                    opacity: 0
                }, time, "swing", function () {
                    // once the animate is complete, set the tracker variables
                    shown = false;
                    // hide the popup entirely after the effect (opacity alone doesn't do the job)
                    popup.css("display", "none");
                });
            }, hideDelay);
        });
    });
});

/* Sidebar Widget Code */
function setupStickyWidget() {
    // handle the stickied widgets on the right toolbar
    var heightToWidget = 0, // variable to hold the height to the widget, this gets updated on ready and on scroll
        isFixed = false,
        newsContainerHeight = $("#newsContainer").height(),
        rightColHeight = $(".rightColContent").height(),
        $rbw = $("#rightBottomWidget"),
        windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
        iframeHeight = windowHeight - 100,
        sidebarTallEnough = ((newsContainerHeight + iframeHeight + 100) > rightColHeight) ? true : false;
    // minBrowserWindow = 1265;

    farkDebug.group("Widget on Load", true);
    farkDebug.log("NewsContainerHeight: " + newsContainerHeight);
    farkDebug.log("rightColHeight: " + rightColHeight);
    farkDebug.log("windowHeight: " + windowHeight);
    farkDebug.log("iframeHeight: " + iframeHeight);
    farkDebug.log("sidebar_tall_enough: " + sidebarTallEnough);
    farkDebug.groupEnd();

    // stickywidget set in right_toolbar.tt
    if (typeof stickyWidget !== "undefined") {
        var $stickyContent = $rbw;
    } else {
        $stickyContent = "";
    }


    setTimeout(function () {
        // setting a timeout because for some reason, sometimes this isn't showing up quickly enough
        resizeWidget();
    }, 1500);

    // if (sidebarTallEnough && $stickyContent.length) {
    if ($stickyContent.length) {
        $(window).on("scroll", function () {
            newsContainerHeight = $("#newsContainer").height();
            rightColHeight = $(".rightColContent").height();
            iframeHeight = windowHeight - 100;
            sidebarTallEnough = ((newsContainerHeight + iframeHeight + 100) > rightColHeight) ? true : false;
            // we check if the sidebar is tall enough here for when the sidebar stuff loads

            if (sidebarTallEnough) {
                // this logic is in here to check and update the height if it's >
                // this could be caused by ads loading or something, so better safe than sorry
                var nowToWidget = $stickyContent.offset().top - 30,
                    windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

                if (nowToWidget > heightToWidget && !isFixed) { heightToWidget = nowToWidget; }

                if (windowWidth >= 1265) {
                    if ($(window).scrollTop() + $(window).height() - 15 > $("#newLayoutTable > tbody > tr:last").offset().top) {
                        $stickyContent.addClass("fixed-bottom");
                        isFixed = true;
                    } else if ($(window).scrollTop() > heightToWidget) {
                        $stickyContent.removeClass("fixed-bottom").addClass("fixed");
                        isFixed = true;
                    } else {
                        $stickyContent.removeClass("fixed fixed-bottom");
                        isFixed = false;
                    }
                }
            }
        });
    }
}

function close_sticky_vids() {
    googletag.destroySlots([fark.globals.billboardStickyAdSlot]);
    var widgetvid = document.getElementById('rightBottomWidgetVids');
    widgetvid.parentNode.removeChild(widgetvid);
    resizeWidget();
    window.scrollBy(0, 1);
    window.scrollBy(0, -1);
    return false;
}

function resizeWidget(isResize) {
    var windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
        iframeHeight = windowHeight - 30,
        egenHeight = windowHeight - 175,
        $egenElement = $("#vblock-80156"),
        $rbwElement = $("#rightBottomWidget"),
        minBrowserWindow = 1265;

    if (windowHeight < minBrowserWindow) {
        $rbwElement.removeClass("fixed fixed-bottom");
    }

    // cap size at 850px (egen is smaller because it takes into account the other egen items...just trust me.)
    iframeHeight = (iframeHeight > 1200) ? 1200 : iframeHeight;
    egenHeight = (egenHeight > 725) ? 725 : egenHeight;

    // if ($stickyContent == $rbw) {
    $rbwElement.height(iframeHeight);

    var height_to_remove = ($("#rightBottomWidgetVids").length) ? 408 : 100;
    //var height_to_remove = ($("#15ec9b58-db5a-477e-ac99-103dcb88aff7").length) ? 278 : 100;
    $rbwElement.find("#widgetTopHeadlineslinkbody").height(iframeHeight - height_to_remove);
    // $("#rightBottomWidget #linkbody").height(iframeHeight-125);
    // $("#rightBottomWidget #sidebarWidgetIframe").height(iframeHeight-135);
    // $("#rightBottomWidget #container").height(iframeHeight - 15);

    // if (typeof isResize == "undefined") {
    //     // $rbwElement.attr("onload", "document.getElementById('rightBottomWidget').contentWindow.resize_sidebar_widget("+iframeHeight+")");
    // } else {
    //     // doing this this way becuase the onload doesn't fire when the iframe is resized
    //     document.getElementById('rightBottomWidget').contentWindow.resize_sidebar_widget(iframeHeight);
    // }
    // } else {
    $egenElement.height(egenHeight);
    // }
}
/* End Sidebar Wiget Code */

(function ($) {
    $.fn.addDesktopError = function (placement, errors, notices) {
        var newhtml = "";
        if (typeof errors == "object" && errors.length) {
            if (placement === "comment") {

                newhtml += "<tr class=\"comment_header_error\"><td colspan=\"4\">" + errors.join("<br>") + "</td></tr>";
            } else if (placement === "reportlink" || placement === "reportprofile") {
                newhtml += "<span class=\"reportlink_error\">Error: " + errors.join(", ") + "</span>";
            }
        }
        if (typeof notices == "object" && notices.length) {
            if (placement === "comment") {
                newhtml += "<tr class=\"comment_header_notice\"><td colspan=\"4\">" + notices.join("<br>") + "</td></tr>";
            } else if (placement === "reportlink" || placement === "reportprofile") {
                newhtml += "<span class=\"reportlink_error\">Error: " + notices.join(", ") + "</span>";
            } else if (placement === "reportprofile") {

            }
        }
        if (placement === "comment") {
            $(this).after(newhtml);
        } else if (placement === "reportlink" || placement === "reportprofile") {
            $(this).append(newhtml);
        }
        return this;
    };
})(jQuery);

function resizeModal(el) {
    // if ( $( el ).length ) {
    //     var $modal = $( el ),
    //         h = $( window ).height(),
    //         w = $( window ).width(),
    //         ch = h * 0.8,
    //         cw = w * 0.8,
    //         top = Math.max( $( window ).height() - ch, 0 ) / 2,
    //         left = Math.max( $( window ).width() - cw, 0 ) / 2;
    //     $modal.css( {
    //         left: left + "px",
    //         top: top + "px",
    //         height: ch + "px",
    //         width: cw + "px",
    //         marginLeft: "auto",
    //         marginTop: "auto"
    //     } );
    // }
}

function checkShowNewToFarkBanner() {
    var maxShowCount = 12,
        hideStorageVal = fark.helpers.getSavedValue("hideWalkthrough"),
        shownStorageVal = fark.helpers.getSavedValue("newToFarkShownCount"),
        loggedInCookieVal = fark.helpers.getCookie("FarkUser"), // using getcookie because we know it's saved as a cookie
        hideNewToFark = (hideStorageVal) ? hideStorageVal : 0,
        shownCount = parseInt(shownStorageVal) || 0;

    if (!hideNewToFark && loggedInCookieVal.substring(0, 4) !== "v7dH" && shownCount < maxShowCount) {
        $(".new_to_fark_p").fadeIn();
        var newShownCount = parseInt(shownCount) || 0;
        newShownCount += 1;
        fark.helpers.setSavedValue("newToFarkShownCount", newShownCount, 365);
    }
}

/* Desktop version of submit ajax */
function processSubmitAjax(data) {

    // Hide submit_errors and submit_notes in case this isn't the first time we're
    //  processing ajax submit on this page
    $(".submit_errors").hide();
    $(".submit_notes").hide();
    if (typeof data.errors !== "undefined" && data.errors.length) {
        var submit_errors_list = "<ul>";
        submit_errors_list += data.errors.map(function (error) {
            return "<li>" + error + "</li>";
        });
        submit_errors_list += "</ul>";
        $(".submit_errors").html(submit_errors_list).show();
    } else if (typeof data.notes !== "undefined" && data.notes.length) {
        var submit_notes_list = "<ul>";
        submit_notes_list += data.notes.map(function (note) {
            return "<li>" + note + "</li>";
        });
        submit_notes_list += "</ul>";
        submit_notes_list += "<span style='color: navy; font-weight: bold;'>Link checks out okay.</span>";
        $(".submit_notes").html(submit_notes_list).show();
    }
    if (data.source_info && data.source_info.ForceTopic && data.source_info.ForceTopic != 0 && data.source_info.ForceTopic !== null) {
        $("select#t").hide();
        var forcedTopic = data.source_info.ForceTopic;
        $("#submit_forced_topic").html("<img src='" + fark_imgurl + "/2012/topics/" + forcedTopic.toLowerCase() + ".gif' alt='" + forcedTopic + "' width='54' height='11' srcset='" + fark_imgurl + "/2012/topics/" + forcedTopic.toLowerCase() + ".gif 1x, " + fark_imgurl + "/2012/topics/" + forcedTopic.toLowerCase() + "-2x.gif 2x'><input type='hidden' name='t' value='" + forcedTopic + "'>").show();
    }
    if (data.source_info && data.source_info.IMG_tagS) {
        if (typeof data.source_info.Image_URI_HiDPI != "undefined" && data.source_info.Image_URI_HiDPI != "") {
            var imageHtml = "<img src=\"" + fark_imgurl + data.source_info.Image_URI_LoDPI + "\" class=\"retina\" alt=\"(" + data.source_info.Name + ")\" title=\"" + data.source_info.Name + "\" srcset=\"" + fark_imgurl + data.source_info.Image_URI_LoDPI + " 1x, " + fark_imgurl + data.source_info.Image_URI_HiDPI + " 2x\" width=\"" + data.source_info.Image_URI_LoDPI_Width + "\" height=\"" + data.source_info.Image_URI_LoDPI_Height + "\">";
        } else {
            var imageHtml = data.source_info.IMG_tagS;
        }
        $("input#srctxt").parent().hide();
        $("#submit_source_image").html(imageHtml).show();
    } else {
        if (!data.errors[0]) {
            $("input#srctxt").val("Some Guy");
        }
    }
    $(".checkingLinkDiv").remove();
    checking_submit_link = 0;
}


window.addEventListener("resize", fark.helpers.debounce(function () {
    positionLoginContainer();
    resizeWidget(true); resizeModal("#modalContainer");
}, 250));

function positionLoginContainer() {
    if (!Modernizr.iphone && document.getElementById('messageCenter')) {
        var elem = document.getElementById('messageCenter'),
            elemWidth = elem.offsetWidth,
            screenWidth = $(window).width();
        if (screenWidth <= 1250) {
            elem.style.left = screenWidth - elemWidth - 30 + "px";
            elem.style.right = "unset";
        } else {
            elem.style.left = "unset";
            elem.style.right = "0px";
        }
    }
}

$(document).ready(function () {
    setupStickyWidget();
    checkShowNewToFarkBanner();
    positionLoginContainer();
});

$(document).ready(function () {
    $(".newsletter_callout form").on('submit', function (event) {
        event.preventDefault();
        var $form = $(this),
            url = $form.attr('action'),
            formvars = $form.serialize();

        $form.find(".mainerr").remove();

        $.post(url, formvars, function (data) {
            var $container = $(".newsletter_callout");
            if (data.success == 0) {
                $container.find("input[type=text]").before('<p class="mainerr">' + data.notes + '</p>');
                $container.find("input[type=submit]").removeClass("disabled").prop("disabled", false);
            } else if (data.success == 1) {
                $container.find("form").remove();
                $container.find("p:first-of-type").after('<p class="mainnotice">' + data.notes + '</p>');
            }
        }).fail(function () {
            var $container = $(".newsletter_callout");
            $container.find("input[type=text]").before('<p class="mainerr">There was a problem signing up for the newsletter. Please try again.</p>');
            $container.find("input[type=submit]").removeClass("disabled").prop("disabled", false);
        });

    });
});

$(document).on("submit", "form#postcomform", function (event) {
    event.preventDefault();

    var thisForm = $(this);
    var thisSubmittedForm = this;
    var data = thisForm.serialize();

    console.log("thisform", thisForm);
    console.log("thissubmittedform", thisSubmittedForm);
    $("input[type='submit'], button[type='submit']", this).prop("disabled", true);

    farkDebug.log(data);
    farkDebug.log("using swear jar?", thisSubmittedForm.usingSwearJar.value);
    farkDebug.log("postmode", thisSubmittedForm.postmode.value);

    var formValid = $.Deferred();
    if (this.usingSwearJar.value == "true") {
        // using swearjar button, check their availability.
        formValid = checkSwearJar();
    } else {
        console.log("no swearjar, just resolve");
        formValid.resolve(true);
    }

    formValid.done(function (val) {
        if (val == false) {
            thisSubmittedForm.usingSwearJar.value = "false";
            $("input[type='submit'], button[type='submit']", thisSubmittedForm).prop("disabled", false);
            return;
        } else {
            // looks like we're good, submit the form
            if (thisSubmittedForm.usingSwearJar.value == "true") {
                if (thisSubmittedForm.postmode.value == '') {
                    var postModeInput = document.createElement('input');
                    postModeInput.type = "hidden";
                    postModeInput.name = "postmode";
                    postModeInput.value = "Add Comment using SwearJar";
                    thisSubmittedForm.appendChild(postModeInput);
                }
            }
            console.log("thissubmnitteform", thisSubmittedForm);
            setTimeout(function () { thisSubmittedForm.submit(); }, 100);
        }
    });
});

